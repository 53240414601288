@import url('https://fonts.googleapis.com/css2?family=League+Spartan&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

body, html {
  margin: 0px;
  height: 100%;
}

.footerlinks {
  font-weight: bold;
  text-decoration: underline;
}
.footerlinks li {
  padding: 5px 0px 5px 0px;
}

input::placeholder {
  color: white;
}

.header-font {
  font-family: 'League Spartan', sans-serif;
  font-size: 54px;
  line-height: 3rem;
}
.body-font {
  font-family: 'League Spartan', sans-serif;;
  font-size: 20px;
}
.sub-body-font {
  font-family: 'League Spartan', sans-serif;
  font-size: 23px;
}
.small-body-font {
  font-family: 'Libre Baskerville', serif;
  font-size: 14px;
}

.chatbox {
  display: flex;
  justify-content: center;
  width: 100%;
}
.chat-log {
  width: 100%;
  padding: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.chat-message {
  display: flex;
  width: 80%;
  border-radius: 5px;
  padding: 10px;
  background-color: #9869e9;
  color: white;
}
.chatgpt {
  color: black;
  background-color: white;
  width: 80%;
  border-radius: 10px;
  padding: 10px;
}
.message {
  overflow-wrap: anywhere;
}
.accedi-height {
  height: calc(100vh - 86px - 175px);
}
.list-container {
  list-style: circle;
}